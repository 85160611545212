import { useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import BusinessServices from "../../../service/business/Business";
import "./business.css";
import { AlertMessage, DatePickers, PageTitle } from "../../components/common";
import { useTranslation } from "react-i18next";
import style from '../../components/common/Common.module.scss';
import { useSelector } from "react-redux";


// import Header from "./Header";

export function NewBusiness() {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);


  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [data, setData] = useState("");
  const [startDate, setDate] = useState(new Date());
  const [notification, setNotification] = useState({
    notify: {
      show: false,
      message: "",
      type: "",
      header: "",
      notifyOn: false,
    },
  });


  const submitFunction = (data) => {
    const service = new BusinessServices();
    service
      .addBusiness({ ...data, dateOfSubmission: startDate })
      .then((res) => {
        if (res.status) {
          setNotification((prev) => ({
            ...prev,
            notify: {
              show: true,
              message: t("Common.success"),
              type: "success",
              notifyOn: true,
            },
          }));
          reset({
            policy_name: "",
            policy_number: "",
            face_amount: "",
            targetPremium: "",
            policy_points: ""
          }, {
            keepErrors: true,
            keepDirty: true,
          });


        } else {
          setNotification((prev) => ({
            ...prev,
            notify: {
              show: true,
              message: t("Common.error"),
              type: "danger",
              notifyOn: true,
            },
          }));
        }
      });
    console.log("data", data);
  };

  const notifyDissmissed = () => {
    setNotification((prevState) => ({
      ...prevState,
      notify: {
        show: false,
        message: "something_went_wrong",
        type: "text",
        header: "dcdscds",
        notifyOn: false,
      },
    }));
  };

  return (
    <>
      {/* <PageTitle title="sales" /> */}


        <h4 style={{fontWeight:'bolder', fontSize:'1.5rem', textAlign:'center', marginTop:'30px', marginBottom:'-30px'}}>Add New Business</h4>
      <div className="parent-business"  >
        <Card className="card-business" style={{color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}}>
          <div className="container-business">
            <form
              style={{ width: "95%" }}
              onSubmit={handleSubmit(submitFunction)}
            >
              <div
                className="input-field-business"
                style={{ paddingTop: "7%" }}
              >
              <label style={{marginBottom:'3px', color:'#383838', color:'#383838', color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}} htmlFor="">Insured Name</label> <br />

                <input
                  className={`inputBox ${errors.policy_name ? "error-outline" : ""}`}
                  {...register("policy_name", { required: true })}
                  // placeholder="Policy name"
                />
              </div>

              {/* <div className="input-field-business">
                <input
                  type="number"
                  min="0"
                  className={`inputBox ${errors.policy_number ? "error-outline" : ""}`}
                  {...register("policy_number", { required: true })}
                  placeholder="Policy number"
                />
              </div> */}
              <div className="input-field-business" >
              <label style={{marginBottom:'3px', color:'#383838', color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}} htmlFor="">Face Amount</label> <br />
                <input
                  type="number"
                  min="0"
                  className={`inputBox ${errors.face_amount ? "error-outline" : ""}`}
                  {...register("face_amount", { required: true })}
                  // placeholder="Face amount"
                />
              </div>
              <div className="input-field-business">
              <label style={{marginBottom:'3px', color:'#383838', color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}} htmlFor="">Target Premium</label> <br />
                <input
                  type="number"
                  min="0"
                  className={`inputBox ${errors.targetPremium ? "error-outline" : ""}`}
                  {...register("targetPremium", { required: true })}
                  // placeholder="Target premium"
                />
              </div>
              <div className="input-field-business">
                <label style={{marginBottom:'3px', color:'#383838', color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}} htmlFor="">Submitted Points</label> <br />
                <input
                  type="number"
                  min="0"
                  className={`inputBox ${errors.policy_points ? "error-outline" : ""}`}
                  {...register("policy_points", { required: true })}
                  // placeholder="Policy points"
                />
              </div>
              <div className="input-field-date">
                {/* <DatePicker
                    className={style.datePicker}
                    showIcon
                    selected={startDate}
                    onChange={(date) => setDate(date)}
                    icon="fa fa-calendar"
                    placeholderText="Date of submission"
                  /> */}

                <label style={{marginBottom:'3px', color:'#383838', color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}`}} htmlFor="">Date of Submission</label> <br />
                <DatePickers
                  disabled={false}
                  start={startDate}
                  datechange={setDate}
                  // id={props.data.code}
                />

              </div>
              {/* <div 
              className="date-picker-div-business"
              >
                <span>
                  <DatePicker
                    className={style.datePicker}
                    showIcon
                    selected={startDate}
                    onChange={(date) => setDate(date)}
                    icon="fa fa-calendar"
                    placeholderText="Date of submission"
                  />
                </span>
              </div> */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input className="submit-business" style={{ height: "35px", width:'90px', backgroundColor: "#7165B9", color: "#FFF" }} type="submit" name="submitButton" value="Submit" />
              </div>
            </form>
          </div>
        </Card>
      </div>
      {notification.notify.show && (
        <AlertMessage
          message={notification.notify.message}
          dismiss={notifyDissmissed}
          type={notification.notify.type}
          show={notification.notify.show}
        />
      )}
    </>
  );
}

export default NewBusiness;
