import React from "react";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../components/common/pageTitle";
import MainContent from "../../components/dashboard/MainContent";
//service
import DashboardService from "../../../service/dashboard/Dashboard";
import AppinfoService from "../../../service/common/Appinfo";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import { useSelector } from "react-redux";

import dummydata from "./dashboard.json";

const Home = (props) => {
  const cplan = useSelector((state) => state.ProjectConfig.mlmPlan);
  const curr = useSelector(state => state.curr);


  const [state, setState] = useState({
    memberStatus: false,
    tiles_loading: true,
    joining_loading: true,
    earnings_loading: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    modal: {
      title: "",
      show: false,
      data: {},
      id: "",
    },
    continue: false,
    visitorid: "",
  });

  const service = new DashboardService();
  const commonService = new AppinfoService();
  const { t } = props;

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const service = new DashboardService();
    const { state } = props.location;

    if (localStorage.getItem("visitorid")) {
      setState((prevState) => ({
        ...prevState,
        modal: {
          title: "Enter OTP sent to your email",
          show: true,
          data: {},
          id: "",
        },
        // visitorid : res.visitor_id,
        continue: true,
      }));
    }

    var userEmail = getCookie("visitor");
    if (state) {
      if (state.error) {
        setState((prevstate) => ({
          ...prevstate,
          alert: {
            show: true,
            varient: "danger",
            message: "validation." + state.message,
          },
        }));
      }
    }
    commonService.checkDemoUsers().then((res) => {
      if (res.data.is_preset_demo && userEmail == null) {
        setState((prevState) => ({
          ...prevState,
          modal: {
            title: "Please fill in your details to continue",
            show: true,
            data: {},
            id: "",
          },
        }));
      } else {
        // props.history.push('/dashboard');
      }
    });
    getDashboard();
    
    // service.DashboardLoad().then((res) => {
    //   if (res.status) {
    //     setState((prevState) => ({
    //       ...prevState,
    //       loading: false,
    //       ...res.data,
    //     }));
    //   } else {
    //     props.history.push("/logout");
    //   }
    // });
    // },[])
  }, []);

  const FiterGraph = (range) => {
    service.JoiningGraphFilter(range).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          joining_graph_data_new: res.data.joining_graph_data_new,
          points_graph_data: res.data.points_graph_data,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const TilesFitler = (type, range) => {
    service.TileFilter(type, range).then((res) => {
      if (res.status) {
        let newData = state.tiles.map((object) => {
          if (object.text === type) {
            return res.data;
          } else {
            return object;
          }
        });
        setState((prevState) => ({
          ...prevState,
          tiles: newData,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  const getDashboard = async() =>{
    setState((prevState) => ({
      ...prevState,
      tiles_loading: false,
      ...dummydata,
    }));
    const reponse =  await Promise.all([service.dashboardTiles(),service.dashboardJoining(),service.dashboardEarnings()])
   
    if(reponse[0].status){
      setState((prevState) => ({
        ...prevState,
        tiles_loading: false,
        ...reponse[0].data,
      }));
    }
    if(reponse[1].status){
      setState((prevState) => ({
        ...prevState,
        joining_loading: false,
        ...reponse[1].data,
      }));
    }
    if(reponse[2].status){
      setState((prevState) => ({
        ...prevState,
        earnings_loading: false,
        ...reponse[2].data,
      }));

    }

  }
 
//const reponse =  await Promise.all([service.dashboardTiles(),service.dashboardJoining(),service.dashboardEarnings()])
  return (
    <div>
      {/* <Spinner /> */}
     
      {/* {state.loading ? (
        
      ) : ( */}
        <React.Suspense fallback={<Spinner />}>
          <Helmet>
            <title>
              {companyName.companyName} | {t("Sidemenu.overview")}
            </title>
          </Helmet>
          {/* <PageTitle title="overview" buttonOn={false} /> */}
          <MainContent
            {...state}
            setstate={setState}
            filterChange={TilesFitler}
            chartfilterChange={FiterGraph}
            alertClose={closeAlert}
            style={{ marginTop: "-2%" }}
            lodervalue={state.loading}
            tiles_loading ={state.tiles_loading}
            joining_loading={state.joining_loading}
            earnings_loading={state.earnings_loading}

          />
        </React.Suspense>
      {/* )} */}
    </div>
  );
};
export default withTranslation()(withRouter(Home));
