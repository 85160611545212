import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import API from "../../api/api";

//import Nophoto from '../../assets/images/nophoto/no_photo.jpg'

export default class DashboardService {
  key = localStorage.getItem("apiKey");

  addBusiness = async (payload) => {
    return API.API.post("home/add-new-business", payload).then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      }
    );
  };
}