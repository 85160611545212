import React, { useEffect, useState } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import { IMG_URL } from '../../../configuration/apiconfig';


function Banner(props) {
    return (

        <section id="home" className="banner_section" style={{ backgroundImage: `url(${props.data.banner_data.banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="container">
                <div className="banner_contant_sec">
                    <h3 dangerouslySetInnerHTML={{ __html: props.data.header_data && props.data.header_data.content?.home_title1 }}></h3>
                    <h1 dangerouslySetInnerHTML={{ __html: props.data.header_data && props.data.header_data.content?.home_title2 }}></h1>
                    <a style={{ background: '#fff', fontSize: '1.3em', fontWeight: '600' }} target="_blank" className="banner_button" href={props.data.header_data.regsitration_url}>REGISTER</a>
                </div>

            </div>
                {/* <div className='banner_reg_btn'>
                    <a style={{ background: '#fff', fontSize: '1.3em', fontWeight: '600' }} target="_blank" className="banner_button" href={props.data.header_data.regsitration_url}></a>
                </div> */}
        </section>
    )
}

export default Banner


