import React from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './network.module.scss';
import { FormInputControl, CustomButton } from '../common';
import Select from 'react-select';
const SearchContent = (props) => {
    console.log('propss',props.searchOptions);
    const { t } = useTranslation();
    const options = [
        { value: 'ca83989', label: 'ca83989 (rahul pk)' },
        { value: 'ca24445', label: 'ca24445' },
        { value: 'ca92666', label: 'ca92666' },
        { value: 'date', label: 'Date' },
        { value: 'grape', label: 'Grape' },
      ];
    return (
        <Card className={style.search}>
            <Row>
                <Col md={5} sm={12}>
                    <div className={style.Buttons}>
                        <Button className={style.downlineButton} onClick={props.buttonClick}>
                            <i className="fa fa-forward"></i>
                            {t(`Sidemenu.${props.buttonLabel}`)}
                        </Button>
                    </div>
                </Col>
                <Col md={7} sm={12}>
                    <Form className={style.searchForm} onSubmit={props.searchClick}>
                        {/* <FormInputControl 
                            required={false}
                            error={props.error}
                            formClass={`mb-0`}
                            inputProps={{
                                type:"text",
                                name:"username",
                                value:props.username,
                                disabled:false,
                                onChange:props.changeHandler
                            }}
                        /> */}
                        <Select
                        // maxMenuHeight={'200px'}
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={colourOptions[0]}
                            onChange={props.changeHandler}
                            // value={props.username}
                            name="username"
                            options={props.searchOptions}
                            placeholder="Search..."
                        />
                        <div>
                            <CustomButton
                                variant="primary"
                                type="submit"
                                btnclass={style.m0}
                                disabled={props.searchloader}
                                style={{height:'38px'}}
                            >
                                {

                                }
                                {props.searchloader ?
                                    <i className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    /> : ''}

                                {t('Button.search')}
                            </CustomButton>

                            <CustomButton
                                style={{height:'38px'}}
                                variant="info"
                                onClick={props.reset}
                                type="button"
                                btnclass={style.m0}
                                disabled={props.resetloader}
                            >
                                {props.resetloader ?
                                    <i className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    /> : ''}
                                {t('Button.reset')}
                            </CustomButton>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Card >
    );
}

export default SearchContent; 