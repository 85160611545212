import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import style from "./dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import EarningsTab from "./EarningsTab";
import TeamPerformance from "./TeamPerformance";
// import TeamPerformanceNew from './TeamPerformanceNew';
import Members from "./Members";
import Charts from "./charts";
import Ranks from "./Ranks";

import DashboardModel from "./DashboardModal";
import { useTranslation } from "react-i18next";

import AddForm from "./AddForm";
import VerificationForm from "./VerificationForm";

import { Dashboard } from "@mui/icons-material";
import ChartData from "./ChartData";
import DashboardService from "../../../service/dashboard/Dashboard";
import { width } from "@mui/system";
function MainContent(props) {
  const { t } = useTranslation();

  const [state, setState] = useState({
    chartValue: {},
  });

  useEffect(() => {
    const service = new DashboardService();

    service.chartTiles().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          chartValue: res.data,
        }));
      }
    });
  }, []);

  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div className={style.dashboard_main_cnt}>
                {props.profile && (
                  <Profiles
                    profile={props.profile}
                    lodervalue={props.tiles_loading}
                  />
                )}

                <Row>
                <Col>
                    <Card style={{borderRadius:'10px'}}>
                      <ChartData
                        data={state.chartValue?.Recruits}
                        name="Recruits"
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{borderRadius:'10px'}}>
                      <ChartData
                        data={state.chartValue?.submittedApplications}
                        name="Applications"
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{borderRadius:'10px'}}>
                      <ChartData
                        data={state.chartValue?.submittedPoints}
                        name="Points"
                      />
                    </Card>
                  </Col>
                 
                </Row>
                <div className={style.chartContent}>

                  <div>
                  {/* <Col xs={12} sm={12} md={4}> */}
                      <Card style={{borderRadius:'10px'}}>
                        <ChartData
                          data={state.chartValue?.licensedAgents}
                          name="Licensed Agents"
                          />
                      </Card>
                    <Card style={{borderRadius:'10px'}}>
                      <ChartData
                        data={state.chartValue?.firstCheque}
                        name="First Check"
                      />
                    </Card>
                    </div>
                  {/* </Col>
                  <Col xs={12} sm={12} md={8}> */}
                  
                    <div style={{width:'100%', paddingLeft:'10px'}}>
                      <Card style={{width:'100%',height:'400px', }}>
                      {props.points_graph_data && (
                        <Charts
                          data={props.points_graph_data}
                          title={"submittedPoints"}
                          filterChange={props.chartfilterChange}
                          lodervalue={props.joining_loading}
                        />
                        )} 
                        </Card>
                    </div>
                    {/* <div style={{background:'#fff', width:'100%', height:'500px'}}></div> */}
                  {/* </Col> */}
                </div>
                <Row 
                className={style.recruits_rowsec}
                >
                  <Col>
                    <div className={style.dash_member_join_rank_sec}>
                      <>
                        {props.joining_graph_data_new && (
                          <Charts
                            data={props.joining_graph_data_new}
                            filterChange={props.chartfilterChange}
                            lodervalue={props.joining_loading}
                            title={"recruits"}
                          />
                        )}
                        {/* {props.rank && <Ranks rank={props.rank} />} */}
                      </>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={style.dash_member_join_rank_sec}>
                      <>
                        {props.new_members && (
                          <Members
                            memberList={props.new_members}
                            lodervalue={props.joining_loading}
                          />
                        )}
                        {/* {props.points_graph_data &&
                          <Charts
                            data={props.points_graph_data}
                            title={'submittedPoints'}
                            filterChange={props.chartfilterChange}
                            lodervalue={props.joining_loading}
                          />
                        } */}

                        {props.rank && <Ranks rank={props.rank} />}
                      </>
                    </div>
                  </Col>
                </Row>

                {/* <Row >
                  <Col>
                    <Card style={{ marginTop: '10px' }}>
                      <Row style={{ margin: '30px 180px' }}>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>

                          <ChartData data={state.chartValue?.Recruits} />

                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                          <ChartData data={state.chartValue?.submittedApplications} />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                          <ChartData data={state.chartValue?.submittedPoints} />
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                          <ChartData data={state.chartValue?.licensedAgents} />
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                          <ChartData data={state.chartValue?.firstCheque} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row> */}

                <div className={style.dashbord_earning_expence}>
                  {props.team_perfomance && (
                    <TeamPerformance
                      topEarners={
                        props.team_perfomance &&
                        props.team_perfomance.top_earners
                      }
                      topRecruters={
                        props.team_perfomance &&
                        props.team_perfomance.top_recruiters
                      }
                      packageoverviews={
                        props.team_perfomance &&
                        props.team_perfomance.package_overview
                      }
                      rankOverviews={
                        props.team_perfomance &&
                        props.team_perfomance.rank_overview
                      }
                      lodervalue={props.earnings_loading}
                      data={props}
                    />
                  )}

                  {props.earnings_nd_expenses && (
                    <EarningsTab
                      lodervalue={props.earnings_loading}
                      income={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.incomes
                      }
                      expenses={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.expenses
                      }
                      payoutstatus={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.payout_statuses
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <DashboardModel show={props.modal.show} title={props.modal.title}>
        {!props.continue && <AddForm {...props}></AddForm>}
        {props.continue && <VerificationForm {...props}></VerificationForm>}
      </DashboardModel>
    </Container>
  );
}

export default MainContent;
