import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import './chartdata.css'

const styles = {
  chartContainer: {
    position: "relative",
    // maxWidth: "400px",
    width: window.innerWidth <= 400 ? '300px' : '400px',
    height: window.innerWidth <= 400 ? "200px" : "250px",
    margin: "auto",
    marginTop:  window.innerWidth <= 400 ? "30px" : "10px",
  },
  centerText: {
    position: "absolute",
    top: "40%",
    // top: window.innerWidth <= 400 ? "35%" : "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontWeight: "bold",
    size: "20px"
 
    },
};



const DoughnutChart = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);

  const data = {
    labels: props.data?.label,
    datasets: [
      {
        data:
          props.data?.value[0] == 0 && props.data?.value.length == 1 
          || props.data?.value.length == 2 && props.data?.value[0] == 0 && props.data?.value[1] == 0  
            ? [1] 
            : props.data?.value,
        backgroundColor: props.data?.color,
      },
    ],
  };

  const options = {
    cutoutPercentage: 65,
    legend: {
      display: false,
      position: "bottom", // Hide the legend including label colors
      labels: {
        fontSize: {
          size: "50px", // Adjust the font size for the displayed labels
        },
      },
    },
    labels: {
      font: {
        size: "50px", // Adjust the font size for the displayed labels
      },
    },

    // Add any other options you want for the chart here
  };

  return (
    <div>
      <h4 style={{ paddingLeft: "20px", fontWeight:"bold" }}>{props.name}</h4>
      <div
       style={styles.chartContainer} 
      //  style={{width: window.innerWidth <= 500 ? '200px' : '400px'}}
      //  className="chartContainer"
       >
        <Doughnut data={data} options={options} responsive />
        <div style={styles.centerText}>
          <p style={{ fontWeight: "bold", color:`${currentTheme == "theme-dark" ? `#fff`: `#000`}` }}>
            {props.data?.value[1] ? props.data?.value[1] : props.data?.value[0]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
