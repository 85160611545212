import React from 'react'
import PageTitle from '../../components/common/pageTitle';
import Helmet from 'react-helmet';
import { DownloadContent } from '../../components/tools';
import {useTranslation} from 'react-i18next';

import companyName from '../../../store/mobxStore/companyName';




function videos() {
  // const { t } = props;
  // const {t} = useTranslation();


  return (
    <div className="h-100">
    <Helmet>
    <title>{companyName.companyName} | VIDEOS</title>
    </Helmet>
    <PageTitle
        title="videos"
        buttonOn={false}
    />
    <DownloadContent isVideo={true} />
</div>
  )
}

export default videos